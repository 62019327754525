<template>
  <div>
    <nav>
      <div class="nav-left">
        <!-- Commented out until datma.fed is available
      <span class="material-symbols-outlined icon-button-close apps-button" title="Apps"
        @click="showapp = !showapp">apps</span>
      -->
        <span class="material-symbols-outlined icon-button-close apps-button empty-spacer"> </span>
        <!-- ^^ remove this line when adding datma.fed-->
        <div class="logo-container" @click="$emit('close-all')">
          <div class="d360-text">dātma.360</div>
          <img :src="require('./img/datma-360-white.png')" class="d360-logo" />
        </div>
      </div>
      <div class="nav-center">
        <a href="https://www.datma.com/" target="_blank" class="logo-link">
          <datma-logo class="datma-logo"></datma-logo>
        </a>
      </div>
      <div class="nav-right">
        <div class="nav-search">
          <i :class="searchIcon.class">
            {{ searchIcon.text }}
          </i>
          <input placeholder="Search Patient or MRN" v-model="searchQuery" @keyup="debounceSearch"
            @focus="searchHasFocus = true" @blur="loseFocus()" @mouseenter="searchHasFocus = true" /><i
            class="material-symbols-outlined search-clear" :class="{ 'hide-close': !searchQuery.length }"
            @click.prevent="clearSearch">close</i>
        </div>
        <div id="profile-icon" class="profile-icon" @click="showAccountMenu = !showAccountMenu"><span
            class="material-symbols-outlined icon-button-close">
            account_circle
          </span></div>
      </div>
      <div v-if="showAccountMenu" class="card flex justify-content-center menu account">
        <Menu id="account-menu">
          <template #start>
            <button @click.prevent="setShowUserProfile()"
              class="w-full p-link flex align-items-left p-2 pl-4 text-color hover:surface-200">
              <span class="material-symbols-outlined icon-button-to-primary">account_circle</span>
              <span class="ml-3 link-text-color">User profile</span>
            </button>
            <button @click.prevent="onSignOut()"
              class="w-full p-link flex align-items-left p-2 pl-4 text-color hover:surface-200">
              <span class="material-symbols-outlined icon-button-to-primary">logout</span>
              <span class="ml-3 link-text-color">Sign out</span>
            </button>
          </template>
        </Menu>
      </div>
    </nav>
    <div v-if="showapp" class="card flex menu apps">
      <Menu id="app-menu">
        <template #start>
          <div class="app-menu-container">
            <span class="app-header">Switch To</span>
            <button class="w-full flex align-items-center p-link mt-1 p-2 text-color hover:surface-200">
              <div class="fed-logo-container">
                <img :src="require('./img/datma-FED-white.png')" />
              </div>
              <span class="ml-3 link-text-color">Datma.Fed</span>
            </button>
          </div>
        </template>
      </Menu>
    </div>
    <div class="results-contianer" v-if="patients.length && searchHasFocus" @mouseenter="resultsMouseover = true"
      @mouseleave="resultsMouseover = false, loseFocus()">
      <table>
        <thead>
          <tr>
            <td :class="{ 'selected-search': currentSortedColumnIndex === SORTED_BY_NAME }"
              @click.prevent="onClickSortTable(SORTED_BY_NAME)">Name <i
                v-if="currentSortedColumnIndex === SORTED_BY_NAME" class="material-symbols-outlined">swap_vert</i></td>
            <td :class="{ 'selected-search': currentSortedColumnIndex === SORTED_BY_GENDER }"
              @click.prevent="onClickSortTable(SORTED_BY_GENDER)">Gender <i
                v-if="currentSortedColumnIndex === SORTED_BY_GENDER" class="material-symbols-outlined">swap_vert</i>
            </td>
            <td :class="{ 'selected-search': currentSortedColumnIndex === SORTED_BY_BIRTH }"
              @click.prevent="onClickSortTable(SORTED_BY_BIRTH)">Birthdate <i
                v-if="currentSortedColumnIndex === SORTED_BY_BIRTH" class="material-symbols-outlined">swap_vert</i></td>
            <td :class="{ 'selected-search': currentSortedColumnIndex === SORTED_BY_MRN }"
              @click.prevent="onClickSortTable(SORTED_BY_MRN)">MRN <i v-if="currentSortedColumnIndex === SORTED_BY_MRN"
                class="material-symbols-outlined">swap_vert</i></td>
          </tr>
        </thead>
        <tbody class="patient-table-body">
          <tr v-for="patient in patients" :key="patient.id" @click.prevent="onSelectPatient(patient)">
            <td class="patient-name">
              <button class="select-patient">{{ patient.name }}</button>
            </td>
            <td>
              {{ patient.gender }}
            </td>
            <td>
              {{ patient.birthdate }}
            </td>
            <td class="font-monospace" :title="patient.id">
              {{ shortenId(patient.id) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      :class="{ 'hidden': (searchQuery.length < MIN_CHARACTERS_FOR_SEARCH || patients.length) || !searchDone && searchHasFocus }"
      class="no-results">No Results
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import Menu from 'primevue/menu'
import DatmaLogo from '@/components/ui/icons/DatmaLogo.vue'
import { debounce, onSignOut, shortenId } from '@/common/shared.js'
import { search } from '@/components/ui/FindPatientModal/search.js'

const store = useStore()
const showapp = ref(false)

const showAccountMenu = ref(false)
const showUserProfile = ref(false)

const searchQuery = ref('')
const searchDone = ref(true)
const patients = ref([])
// ******** TODO: gmlewis - clean this up ********
let lastSortedColumnIndex = 0
let lastSortOrder = true

const sortOrderAsc = ref(true)
const searchHasFocus = ref(false)
const resultsMouseover = ref(false)

const searchIcon = computed(() => {
  if (searchDone.value || searchQuery.value.length < MIN_CHARACTERS_FOR_SEARCH) {
    return { class: 'material-symbols-outlined', text: 'search' }
  } else {
    return { class: 'material-symbols-rounded rotate', text: 'progress_activity' }
  }
})

const MIN_CHARACTERS_FOR_SEARCH = 3

const SORTED_BY_NAME = 0
const SORTED_BY_GENDER = 1
const SORTED_BY_BIRTH = 2
const SORTED_BY_MRN = 3

const currentSortedColumnIndex = ref(SORTED_BY_NAME)

const loseFocus = () => {
  if (resultsMouseover.value) return
  setTimeout(() => {
    if (!resultsMouseover.value)
      searchHasFocus.value = false
  }, 500)
}

const columnCompareFuncs = {
  0: (a, b) => a.name.localeCompare(b.name),
  1: (a, b) => a.gender.localeCompare(b.gender),
  2: (a, b) => a.birthdate.localeCompare(b.birthdate),
  3: (a, b) => a.id.localeCompare(b.id),
}

const onSearchForPatient = async () => {
  const query = searchQuery.value
  searchDone.value = false
  if (query.length < MIN_CHARACTERS_FOR_SEARCH) {
    patients.value = []
    return
  }
  const results = await search(query)
  if (query.length >= MIN_CHARACTERS_FOR_SEARCH) { /// in case query completes after removing query characters
    patients.value = results
  }
  sortTable(0)
  searchDone.value = true
}

const debounceSearch = debounce(onSearchForPatient, 400)

const compareFunc = (columnIndex, ascending) => {
  return (a, b) => {
    const f = columnCompareFuncs[columnIndex]
    return ascending ? f(a, b) : -f(a, b)
  }
}

const onClickSortTable = (index) => {
  if (index !== currentSortedColumnIndex.value) {
    lastSortedColumnIndex = currentSortedColumnIndex.value
    lastSortOrder = sortOrderAsc.value
    sortOrderAsc.value = true
  } else {
    sortOrderAsc.value = !sortOrderAsc.value
  }
  currentSortedColumnIndex.value = index
  sortTable(index)
}

const sortTable = (index) => {
  const firstCompare = compareFunc(index, sortOrderAsc.value)
  const secondCompare = compareFunc(lastSortedColumnIndex, lastSortOrder)
  const bothCompares = (a, b) => {
    const result1 = firstCompare(a, b)
    if (result1 === 0) { return secondCompare(a, b) }
    return result1
  }
  patients.value.sort(bothCompares)
}

const clearSearch = () => {
  searchQuery.value = ''
  patients.value = []
}

const onSelectPatient = (patient) => {
  store.dispatch('setRecordSession', { isStartRecordSession: false, publish: true })
  store.dispatch('setClickedCurrentSession', { isClickedCurrentSession: false, publish: true })
  store.dispatch('setSessionPatient', { patient, publish: true })
  searchQuery.value = ''
  patients.value = []
}

document.addEventListener('click', function (event) {
  const profileIcon = document.getElementById('profile-icon')
  const appsIcon = document.querySelector('.apps-button')

  if (!profileIcon.contains(event.target)) {
    showAccountMenu.value = false
  }
  if (!appsIcon.contains(event.target)) {
    showapp.value = false
  }
})

const setShowUserProfile = () => {
  showAccountMenu.value = !showAccountMenu.value
  showUserProfile.value = !showUserProfile.value
  store.dispatch('showViewer', false)
  store.dispatch('setShowUserProfile', { showUserProfile, publish: true })
}
</script>

<style lang="scss" scoped>
.app-menu-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem 0;

  .app-header {
    padding-left: 0.75rem;
    font-weight: 600;
  }

  .fed-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--datmaFed);
    width: 36px;
    height: 36px;
    border-radius: 5px;

    img {
      height: 16px;
      width: 16px;
    }
  }
}

.app-selector-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

  &:hover .app-icon-color-background {
    cursor: pointer;
    opacity: 1;
    transition: all ease-out 200ms;
  }

  &:hover .app-name-container {
    color: var(--White);
    transition: all ease-out 200ms;
  }
}

.app-name-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 20px;
  font-weight: 600;
  font-size: .9rem;
  transition: all ease-out 200ms;
}

button {
  align-items: center;
}

.app-icon-container {
  margin: 5px 5px;
  height: 56px;
  width: 56px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dfed {
  position: absolute;
  top: 11px;
  left: 12px;
}

.app-icon-color-background {
  background-color: var(--datmaFed);
  width: 56px;
  height: 56px;
  opacity: 0;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all ease-out 200ms;
}

nav {
  background-color: var(--Black);
  color: var(--Grey500);
  height: 70px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-left {
  display: flex;
  margin-left: 7px;
  align-items: center;
  justify-content: left;
  width: 33%;

  .apps-button {
    cursor: pointer;
  }

  .d360-text {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: var(--White);
    margin: 0 15px 0 0.25rem;
  }

  .d360-logo {
    width: 1.5rem;
    height: 1.5rem;
  }

  span {
    margin-right: 20px;
  }
}

.logo-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.nav-right {
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: right;
  width: 33%;
}

.nav-center {
  display: flex;
  margin: 0 20px;
  align-items: center;
  justify-content: center;
  width: 33%;

  .datma-logo {
    cursor: pointer;
  }

  .logo-link {
    height: 37px;
    margin-bottom: 1.7rem;
  }
}

#nav-next-patient,
.nav-next-patient-block {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

#nav-next-patient {
  padding-left: 10px;
  font-size: 0.9rem;
}

.datma-360-logo {
  position: absolute;
  top: 10px;
  margin: auto;
}

.profile-icon {
  margin: 0 20px;
}

.profile-icon {
  &:hover {
    cursor: pointer;

    span {
      color: #fff;
    }
  }
}

.nav-search {
  background-color: var(--Grey900);
  border-radius: 50px;
  display: flex;
  align-items: center;
  padding: 0 5px;
  max-width: 250px;
  // TODO: fix text jump in input on fresh render

  &input {
    background: transparent;
    border: none;
  }
}

.nav-search-icon {
  height: 30px;
  width: 30px;
  margin-top: 6px;
  margin-left: 7px;
  border-radius: 10px 0 0 10px;
  cursor: pointer;
  margin: 6px;
  padding-left: 5px;

  &:hover {
    cursor: pointer;

    span {
      color: #fff;

    }
  }
}

.nav-search {
  i:first-child {
    margin: 6px;
  }

  i:last-child {
    margin: 6px;
    font-size: 18px;
  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    flex-grow: 1;
    color: var(--White);
    margin: 0 5px;
    font-size: 1rem;
    height: 30px;
  }
}

.menu {
  position: absolute;
  z-index: 100;
  padding: 8px 0px;
  border-radius: 6px;
  background: var(--Grey100);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);

  &.account {
    top: 75px;
    right: 16px;
  }

  &.apps {
    top: 75px;
    left: 16px;
  }

  .link-text-color:hover {
    color: var(--Primary);
  }
}

.align-items-left {
  justify-content: flex-start;
}


.results-contianer {
  position: absolute;
  top: 75px;
  background-color: var(--White);
  right: 70px;
  padding: 0px;
  z-index: 100;
  box-shadow: 2px 2px 6px 1px var(--Grey500);
  border-radius: 6px;
  max-height: calc(100vh - 103px);
  overflow-y: auto;
}

.results-contianer table {
  font-size: clamp(0.7rem, 1.3cqi, 1.1rem);
  border-collapse: collapse;
}

.results-contianer table thead tr td {
  background-color: var(--Grey200);
  cursor: pointer;
  vertical-align: middle;
  font-size: clamp(0.7rem, 1.3cqi, 1rem);
  position: sticky;
  top: 0;
  z-index: 2;
}

.results-contianer table thead tr td i {
  position: absolute;
  font-size: 16px;
  font-variation-settings: "FILL" 0, "wght" 600, "GRAD" 0, "opsz" 48;
  margin-left: 3px;
}

.results-contianer table thead tr td.selected-search {
  font-weight: bold;
}

.results-contianer table tbody tr:nth-child(even) {
  background-color: var(--Grey100)
}

.results-contianer table tr:hover {
  background-color: var(--PrimaryLight) !important;
  background-blend-mode: screen;
  transition: all linear 200ms;
  cursor: pointer;

  button {
    color: var(--Black);
  }
}

.results-contianer table tr td {
  padding: 1cqi 2cqi;
}

.results-contianer table tr td:first-child {
  text-align: left;
  margin-left: 10px;
}

.results-contianer table tr td button {
  color: var(--Primary);
  font-size: clamp(0.7rem, 1.3cqi, 1rem);
  background-color: transparent;
  outline: transparent;
  border: transparent;
  cursor: pointer;
  padding-left: 0;
}

.rotate {
  animation: spin 1s linear infinite;
  color: var(--MarketingMagenta) !important;

}

.hidden {
  display: none;
}

.hide-close {
  color: var(--Grey900) !important;
}

.no-results {
  padding: 20px;
  color: var(--ErrorRedDark);
  font-weight: 700;
  position: absolute;
  z-index: 100;
  background-color: var(--White);
  top: 75px;
  right: 87px;
  width: 244px;
  box-shadow: 2px 2px 6px 1px var(--Grey500);
  border-radius: 6px;
}

.search-clear {
  cursor: pointer;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>


<!-- Styles for primevue component -->
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity ease 200ms;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.p-link {
  background-color: var(--Grey100);
}

.p-menu {
  padding: 0;
  border: none;
  background-color: var(--Grey100);
}

.empty-spacer {
  width: 11px;
}
</style>
