<template>
  <div class="container">
    <div class="left-pane">
      <button class="w-full p-link flex align-items-center p-2 pl-4 hover:surface-200" @click.prevent="handleCancel">
        <span class="material-symbols-outlined icon-button-to-primary">home</span>
        <span class="ml-3 link-text-color">Home</span>
      </button>
      <div class="w-full p-link flex align-items-center p-2 pl-4 hover:surface-200">
        <span class="material-symbols-outlined icon-button-to-primary icon-selected">account_circle</span>
        <span class="ml-3 link-text-color icon-selected">User profile</span>
      </div>
    </div>
    <div class="right-pane">
      <div class="header-section">
        <h2 class="header-user-profile">User Profile</h2>
        <label class="sub-header">Settings for your personal profile</label>
      </div>
      <hr class="horizontal-line" />
      <form>
        <div class="form-controls">
          <div class="form-group first-name">
            <label for="first-name">First name</label>
            <input v-model="firstName" type="text" id="first-name" required
              :class="{ 'red-border': isEmpty(firstName) }" />
          </div>
          <div class="form-group last-name">
            <label for="last-name">Last name</label>
            <input v-model="lastName" type="text" id="last-name" required :class="{ 'red-border': isEmpty(lastName) }" />
          </div>
          <div class="form-group display-name">
            <label for="display-name">Display name</label>
            <input type="text" id="display-name" v-model="displayName" required
              :class="{ 'red-border': isEmpty(displayName) }" />
          </div>
        </div>
        <hr class="horizontal-line" />
        <div class="email-section">
          <h2 class="email-user-profile">Email</h2>
          <div class="email-sub-header">
            <span class="email-id">{{ email || 'No email address found' }}</span>
          </div>
        </div>
        <hr class="horizontal-line" />
        <div class="lan-reg-section">
          <h2 class="lan-reg-user-profile">Language & region</h2>
          <label class="lan-reg-sub-header">Changes to your language and timezone will be reflected across all
            modules.</label>
          <div class="form-controls">

            <div class="language">
              <label for="language">Language</label>
              <select role="preferredLanguage" id="language" name="language" v-model="language"
                :class="{ 'red-border': isEmpty(language) }">
                <option v-for="(value, key) in languages" :key="value" :value="value">
                  {{ key }}
                </option>
              </select>
            </div>

            <div class="timezone">
              <label for="timezone">Timezone</label>
              <select role="preferredTimeZone" id="timezone" name="timezone" v-model="timeZone"
                :class="{ 'red-border': isEmpty(timeZone) }">
                <option v-for="timeZone in timeZones" :key="timeZone.id" :value="timeZone.id">
                  {{ timeZone.name }}
                </option>
              </select>
            </div>

          </div>
          <div class="buttons">
            <button class="primary-outlined-button cancel-btn" @click.prevent="handleCancel">Cancel</button>
            <button class="primary-filled-button save-btn" @click.prevent="saveProfileChangesBuild">Save changes</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <Dialog :visible="confirmationVisible" modal header="Unsaved changes">
    <p>Are you sure you want to discard these changes?</p>
    <div class="flex gap-2">
      <button class="primary-outlined-button cancel-btn" @click.prevent="handleModalCancel">Cancel</button>
      <button class="primary-filled-button save-btn" @click.prevent="loadLandingPage">Discard</button>
    </div>
  </Dialog>
</template>

<script setup>
// Copyright (C) dātma, inc™ - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, defineEmits, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { timeZones } from '@/common/shared.js'
import { languages } from '@/plugins/translation.js'
import { saveProfileChanges } from './saveUserProfile.js'
import Dialog from 'primevue/dialog'
import { showToast } from '../../../utils/utils'

const store = useStore()

const keycloakId = ref('')
const profile = computed(() => store.getters.profile)
const firstName = ref('')
const lastName = ref('')
const email = ref('')
const displayName = ref('')
const timeZone = ref('')
const language = ref('')
const confirmationVisible = ref(false)
const emit = defineEmits(['home-clicked'])

onMounted(() => {
  keycloakId.value = profile.value.keycloakId
  firstName.value = profile.value.firstName
  lastName.value = profile.value.lastName
  email.value = profile.value.email
  displayName.value = profile.value.displayName
  timeZone.value = profile.value.timeZone
  language.value = profile.value.language
})

const saveProfileChangesBuild = async () => {
  if (!validate(firstName, 'First name') ||
    !validate(lastName, 'Last name') ||
    !validate(displayName, 'Display name') ||
    !validate(timeZone, 'Timezone') ||
    !validate(language, 'Language')) { return }

  const updatedProfileDetails = {
    id: keycloakId.value,
    firstName: firstName.value,
    lastName: lastName.value,
    attributes: {
      displayName: displayName.value,
      timeZone: timeZone.value,
      language: language.value,
    },
    email: email.value,
  }

  const response = await saveProfileChanges(updatedProfileDetails)

  if (response.ok) {
    showToast('success', 'Changes saved')

    // The store expects a different structure than the api
    const profile = {
      id: keycloakId.value,
      firstName: firstName.value,
      lastName: lastName.value,
      displayName: displayName.value,
      timeZone: timeZone.value,
      language: language.value,
      email: email.value,
    }
    store.dispatch('saveProfile', { profile })
  } else {
    showToast('error', 'Changes not saved')
  }
}

const validate = (field, fieldName) => {
  if (field.value) { return true }
  showToast('error', `${fieldName} field cannot be empty`)
  return false
}

const isEmpty = (fieldValue) => !fieldValue

const handleCancel = () => {
  if (
    profile.value.firstName === firstName.value &&
    profile.value.lastName === lastName.value &&
    profile.value.displayName === displayName.value &&
    profile.value.timeZone === timeZone.value &&
    profile.value.language === language.value &&
    profile.value.email === email.value
  ) {
    emit('home-clicked')
  } else {
    confirmationVisible.value = true
  }
}

const handleModalCancel = () => {
  confirmationVisible.value = false
}

const loadLandingPage = () => {
  emit('home-clicked')
}
</script>

<style scoped>
.form-group,
.language,
.timezone {
  margin-bottom: 20px;
  text-align: left;
  margin-left: 2rem;
}

.header-section,
.email-section,
.lan-reg-user-profile,
.lan-reg-sub-header {
  margin-left: 2rem;
}

.last-name,
.timezone {
  margin-right: 2rem;
}

.cancel-btn,
.save-btn {
  width: 130px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input,
select {
  width: 33rem;
  height: 50px;
  padding: 8px;
  border: 1px solid var(--Grey600);
  border-radius: 12px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 20px 90px;
}

.container {
  display: flex;
  flex-direction: row;
}

.left-pane {
  border-right: 1px solid var(--Grey500);
  padding: 20px 10px;
  width: 20%;
  min-width: 220px;
}

.right-pane {
  padding: 20px 100px;
  flex-grow: 1;
  background-color: var(--White);
  height: 95vh;
  overflow: auto;
}

.header-section,
.email-section,
.lan-reg-section {
  text-align: left;
}

.sub-header,
.email-sub-header,
.lan-reg-sub-header {
  margin-bottom: 20px;
  font-weight: normal;
}

.form-controls {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
}

.horizontal-line {
  margin: 40px 0;
  border: none;
  border-top: 1px solid var(--Grey300);
  height: 1px;
}

select {
  background-color: var(--White)
}

.red-border {
  border: 2px solid var(--ErrorRedDark);
}

.link-text-color:hover {
  color: var(--Primary);
}
</style>
